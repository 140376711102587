import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue';
import router from './routers'
import axios from 'axios'
import vcolorpicker from 'vcolorpicker'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'ant-design-vue/dist/antd.css';
import './assets/common.css'
import 'vue-directive-image-previewer/dist/assets/style.css'
import 'core-js'
import VueUeditorWrap from 'vue-ueditor-wrap'
import vueAppend from 'vue-append'
Vue.use(vueAppend);
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
Vue.prototype.axios = axios
Vue.use(Antd);
Vue.use(VueDirectiveImagePreviewer, {
  background: {     // or : background: '#000'
    color: '#000' // or rgba or rgb     // or image: 'url(xxx)'
  },
  // transition
  animate: {
    duration: 600,
    delay: 500
  },
  // loading (not supported)
  loading: {
    image: ''
  },
   zIndex: 9999, 
  // cursor(css)
  cursor: 'pointer',
  clickMethod: 'doubleClick'   // click or doubleClick(not supported)
})
Vue.config.productionTip = false
Vue.use(vcolorpicker)
import Clipboard from 'clipboard' // 复制
Vue.prototype.Clipboard=Clipboard

// message.config({
//   top: `100vh`,
//   maxCount: 2,
// });
axios.interceptors.request.use(config => {
  config.headers.token = window.localStorage.getItem("token");
  return config;
})
//状态码
var code = function (res) {
  if (res.data.code == 200) {
    return true;
  } else if (res.data.code == -1) {
    window.localStorage.setItem("token", "");
    window.localStorage.setItem("name", "");
    window.localStorage.setItem("id", "");
    axios.defaults.headers.post["token"] = "";
    message.error(res.data.msg);
    router.push("/login");
    return false;
  }else {
    message.error(res.data.msg);
    return false;
  }
}
Vue.prototype.$code = code
//退出登录
var logOut = function () {
  // axios.post("/shopAdminApi/user/logout").then((res) => {
  //   if (code(res)) {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("name", "");
      window.localStorage.setItem("id", "");
      axios.defaults.headers.post["token"] = "";
      router.push("/login");
  //   }
  // });
};
Vue.prototype.$logOut = logOut;
// 导出Excel公用方法
Vue.prototype.$exportMethod = function (data) {
  axios.get(`${data.url}`, {
    headers: {
      token: window.localStorage.getItem("token"), 'Content-Type': 'application/vnd.ms-excel; charset=UTF-8'
    }, responseType: 'blob'
  }).then(function(res){
    const link = document.createElement('a')
    var blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)

    // link.download = res.headers['content-disposition'] //下载后文件名
    link.download = data.fileName //下载的文件名
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link);
    document.body.removeChild(link)
  }).catch(error => {
    this.$Notice.error({
      title: '错误',
      desc: '网络连接错误'
    })
    console.log(error)
  })
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
