import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode:"history",
    base:"/",//process.env.BASE_+URL
    routes:[
      {
        path: '/index',
        component: () => import('@/view/indexPage'), 
      },
      {
        path: '/user',
        component: () => import('@/view/User'), 
      },
      {
        path: '/upgrade',
        component: () => import('@/view/Upgrade.vue'), 
      },
      {
        path: '/tbMarketing',
        component: () => import('@/view/tbMarketing.vue'), 
      },
      {
        path: '/order',
        component: () => import('@/view/Order/index.vue'), 
        children:[
          { 
            path: 'list', 
            component: () => import('@/view/Order/list.vue'),
          },
          {
            path: 'first',
            component: () => import('@/view/Order/first.vue'), 
          },
        ]
      },
      {
        path: '/robot',
        component: () => import('@/view/Robot'), 
      },
      {
        path: '/assignment',
        component: () => import('@/view/Assignment/index.vue'), 
        children:[
          { 
            path: 'command', 
            component: () => import('@/view/Assignment/command.vue'),
          },
          { 
            path: 'reward', 
            component: () => import('@/view/Assignment/reward.vue'),
          },
        ]
      },
      {
        path: '/withdraw',
        component: () => import('@/view/Withdraw.vue'), 
      },
      { 
        path: '/activity', 
        component: () => import('@/view/Activity/index'),
        children:[
          { 
            path: 'list', 
            component: () => import('@/view/Activity/list'),
          },
          { 
            path: 'task', 
            component: () => import('@/view/Activity/task'),
          },
          { 
            path: 'task/list', 
            component: () => import('@/view/Activity/tasklist'),
          },
        ]
      },
      {
        path: '/poster',
        component: () => import('@/view/Poster'), 
      },
      { 
        path: '/credentials', 
        component: () => import('@/view/Credentials/index'),
        children:[
          { 
            path: 'list', 
            component: () => import('@/view/Credentials/list'),
          },
          { 
            path: 'check', 
            component: () => import('@/view/Credentials/check'),
          },
          { 
            path: 'h5', 
            component: () => import('@/view/Credentials/h5.vue'),
          },
        ]
      },
      {
        path: '/register',
        component: () => import('@/view/register'), 
      },
      {
        path: '/loginApp',
        component: () => import('@/view/loginApp'), 
      },
      {
        path: '/centerApp',
        component: () => import('@/view/centerApp'), 
      },
      {
        path: '/pullNewOrder',
        component: () => import('@/view/pullNewOrder'), 
      },
      {
        path: '/activityGuide',
        component: () => import('@/view/ActivityGuide.vue'), 
      },
      {
        path: '/login',
        component: () => import('@/view/Login'), 
      },
      {
        path: '/download',
        component: () => import('@/view/download'), 
      },
      
      {
        path: '/h5/activity',
        component: () => import('@/view/h5/activity.vue'), 
      },
      
      {
        path: '/h5/jd2021',
        component: () => import('@/view/h5/jd2021_activity.vue'), 
      },
      {
        path: '/h5/jd2021Login',
        component: () => import('@/view/h5/jd2021_login.vue'), 
      },
      {
        path: '/h5/jd2021Center',
        component: () => import('@/view/h5/jd2021_center.vue'), 
      },
      {
        path: '/h5/jd202111',
        component: () => import('@/view/h5/jd2021_activity_h5.vue'), 
      },
      {
        path: '/h5/jd202111Center',
        component: () => import('@/view/h5/jd2021_center_h5.vue'), 
      },
      {
        path: '/h5/firstReward',
        component: () => import('@/view/h5/firstReward.vue'), 
      },
      {
        path: '/mikui',
        component: () => import('@/view/h5/mikui.vue'), 
      },
      {
        path: '/h5/jd202112',
        component: () => import('@/view/h5/jd202112.vue'), 
      },
      // { 
      //   path: '/trilogy', 
      //   component: () => import('@/view/Trilogy/index'),
      //   children:[
      //     { 
      //       path: 'list', 
      //       component: () => import('@/view/Trilogy/list'),
      //     },
      //     { 
      //       path: 'add', 
      //       component: () => import('@/view/Trilogy/add'),
      //     },
      //     { 
      //       path: 'edit', 
      //       component: () => import('@/view/Trilogy/edit'),
      //     },
      //   ]
      // },
      { 
        path: '/firstReward', 
        component: () => import('@/view/firstReward/index'),
        children:[
          {
            path: 'goods',
            component: () => import('@/view/firstReward/goods.vue'), 
          },
          {
            path: 'empower',
            component: () => import('@/view/firstReward/empower.vue'), 
          },
          {
            path: 'users',
            component: () => import('@/view/firstReward/user.vue'), 
          },
          {
            path: 'reward',
            component: () => import('@/view/firstReward/reward.vue'), 
          },
        ]
      },
      { 
        path: '/otherSet', 
        component: () => import('@/view/OtherSet/index'),
        children:[
          {
            path: 'paySet',
            component: () => import('@/view/OtherSet/paySet.vue'), 
          },
          { 
            path: 'notice', 
            component: () => import('@/view/OtherSet/notice'),
          },
          { 
            path: 'service', 
            component: () => import('@/view/OtherSet/service'),
          },
          { 
            path: 'banner', 
            component: () => import('@/view/OtherSet/banner'),
          },
          {
            path: 'trilogy',
            component: () => import('@/view/OtherSet/Trilogy.vue'), 
          },
          {
            path: 'version',
            component: () => import('@/view/OtherSet/version.vue'), 
          },
          {
            path: 'appReview',
            component: () => import('@/view/OtherSet/appReview.vue'), 
          },
          {
            path: 'switchUrl',
            component: () => import('@/view/OtherSet/switchUrl.vue'), 
          },
          {
            path: 'jdPullNew',
            component: () => import('@/view/OtherSet/jdPullNew.vue'), 
          }
        ]
      },
      {
        path: '*',
        redirect:'/index', 
      },
    ]
  })
//  router.beforeEach((to,from,next)=>{
//    if(to.path==="/register"){
//      return next()
//    }
//    const tokenStr=window.localStorage.getItem("Authorization")
//    if(!tokenStr){
//      return next("/register")
//    }
//    next()
//  }) 
export default router